import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import XHR from 'i18next-xhr-backend';

const backendOpts = {
    loadPath: process.env.REACT_APP_API_URL + `app/translation_{{lng}}`
}

i18n
    .use(LanguageDetector)
    .use(XHR)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        backend: backendOpts,
        fallbackLng: 'en',
        load: 'languageOnly',
        react: {
            transSupportBasicHtmlNodes: true,
        }
    });

export default i18n;