export const menu = [
    {
        href: '/#section-about',
        to: 'section-about',
        name: 'header.about',
    },
    {
        href: '/#section-offer',
        to: 'section-offer',
        name: 'header.offer',
    },
    {
        href: '/#section-production',
        to: 'section-production',
        name: 'header.production',
    },
    {
        href: '/#section-achievements',
        to: 'section-achievements',
        name: 'header.achievements',
    },
    {
        href: '/#section-contact',
        to: 'section-contact',
        name: 'header.contact',
    },

];
