import React, {Fragment, useEffect, useState} from 'react';
import Select from "react-select";
import poland from "./../images/004-poland.svg";
import uk from "./../images/005-united-kingdom.svg";
import germany from "./../images/006-germany.svg";
import {menu} from "./home/menu";
import {useTranslation} from "react-i18next";
import GoToTop from "./utilites/GoToTop";
import {Link} from "react-scroll";
import {useLocation} from "react-router-dom";

const Footer = () => {

    const [t, i18n] = useTranslation();

    const {pathname} = useLocation();
    const [mainPage, setMainPage] = useState(window.location.pathname === '/');

    useEffect(() => {
        setMainPage(pathname === '/')
    },[pathname])

    const options = [
        {value: 'pl', label: (<>Polski <img alt={'Polski'} src={poland} width={16} height={16}/></>)},
        {value: 'en', label: (<>English <img alt={'English'} src={uk} width={16} height={16}/></>)},
        {value: 'de', label: (<>Deutsch <img alt={'Deutsch'} src={germany} width={16} height={16}/></>)}
    ];


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
        option: (provided) => ({
            ...provided,
            color: '#fff',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        }),
        control: (provided) => ({
            ...provided,
            border: 'none',
            ':hover': {
                borderColor: 'transparent'
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            border: 'none'
        }),
        singleValue: base => ({
            ...base,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        })
    }

    const colors = {
        primary: '#2e261e',
        primary75: '#2e261e',
        primary50: '#2e261e',
        primary25: '#2e261e',
        dangerLight: '#fff',
        neutral0: '#382E25',
        neutral5: 'hsl(100%, 100%, 80%)',
        neutral10: 'hsl(100%, 100%, 90%)',
        neutral20: 'hsl(100%, 100%, 80%)',
        neutral30: 'hsl(100%, 100%, 70%)',
        neutral40: 'hsl(100%, 100%, 60%)',
        neutral50: 'hsl(100%, 100%, 50%)',
        neutral60: 'hsl(100%, 100%, 40%)',
        neutral70: 'hsl(100%, 100%, 30%)',
        neutral80: 'hsl(100%, 100%, 20%)',
        neutral90: 'hsl(100%, 100%, 10%)',
    };

    const handleTheme = (theme) => {
        return (
            {
                ...theme,
                borderRadius: 0,
                colors
            });
    }

    const handleChangeLanguage = (locale) => {
        i18n.changeLanguage(locale.value);
    }

    const menuLength = menu.length;
    return (
        <footer id="footer">
            <div id="copyrights" className="bg-color">

                <div className="container clearfix">

                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5">
                            Copyrights &copy; {new Date().getFullYear()} Delta-Rudnik | All Rights Reserved.
                        </div>

                        <div className="col-lg-5 d-lg-flex flex-lg-column align-items-lg-end mt-4 mt-lg-0">
                            <div className="copyrights-menu copyright-links clearfix pr-5">
                                {menu.map((link, index) => (
                                    <Fragment key={index}>
                                        {mainPage ?
                                            <Link href={link.href} to={link.to} hashSpy={true} smooth={true}>
                                                <div>{t(link.name)}</div>
                                            </Link>
                                            :
                                            <a href={link.href}><div>{t(link.name)}</div></a>
                                        }
                                        {index + 1 === menuLength || "/"}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-2 d-lg-flex flex-lg-column align-items-lg-end mt-4 mt-lg-0">
                                <Select className={'w-100'}
                                        options={options}
                                        aria-label={'language'}
                                        defaultValue={options.filter(option => {
                                            return i18n.languages[0] === option.value
                                        })}
                                        menuPlacement={'top'}
                                        theme={handleTheme}
                                        isSearchable={false}
                                        onChange={handleChangeLanguage}
                                        styles={customStyles}
                                />



                        </div>
                    </div>
                </div>

            </div>
            <GoToTop />
        </footer>
    );
}
export default Footer;