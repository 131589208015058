import React, {lazy, Suspense} from 'react';
import './style.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './i18n.js';
import Spinner from "react-bootstrap/Spinner";
import {ParallaxProvider} from "react-scroll-parallax";

const Home = lazy(() => import('./components/Home'));
const Catalog = lazy(() => import('./components/catalog/Catalog'));

function App() {
    return (
        <Suspense fallback={<Spinner animation={'border'}/>}>
            <ParallaxProvider>
                <Router>
                    <Header/>
                    <div className={'content-wrapper'}>
                        <Switch>
                            <Route path="/catalog" component={Catalog}/>
                            <Route path="/" component={Home}/>
                        </Switch>
                    </div>
                    <Footer/>
                </Router>
            </ParallaxProvider>
        </Suspense>
    );
}

export default App;
