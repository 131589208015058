import React, {useEffect, useState} from 'react';
import {menu} from "./home/menu";
import logo from '../logo.svg';
import {useTranslation} from "react-i18next";
import { Link } from 'react-scroll'
import {useLocation} from "react-router-dom";

const Header = () => {

    const [t] = useTranslation();
    const {pathname} = useLocation();
    const [mainPage, setMainPage] = useState(window.location.pathname === '/');
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const stickyHeader = mainPage ? 'sticky-header' : 'no-sticky';

    useEffect(() => {
        setMainPage(pathname === '/')
    },[pathname])


    return (
        <header id="header" className={"header-size-custom border-bottom-0 " + stickyHeader}>
            <div id="header-wrap">
                <div className="container">
                    <div className="header-row justify-content-lg-between">
                        <div id="logo" className="mr-lg-5">
                            <a href="/"><img src={logo} alt="Logo" width="70" height="64" /></a>
                        </div>
                        <div className="header-misc">
                            <a href={'/catalog'} className={'button button-rounded button-light'}><div>{t('header.product')}</div></a>
                        </div>

                        <div id="primary-menu-trigger"  onClick={() => setShowMobileMenu(!showMobileMenu)}>
                            <svg className="svg-trigger" viewBox="0 0 100 100">
                                <path
                                    d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"/>
                                <path d="m 30,50 h 40"/>
                                <path
                                    d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"/>
                            </svg>
                        </div>

                        <nav className="primary-menu with-arrows mr-lg-auto">
                            <ul className={'menu-container align-self-start' + (showMobileMenu && ' d-block')}>
                                {menu.map(link => (
                                    <li key={link.href} className="menu-item">
                                        {mainPage ?
                                            <Link href={link.href} to={link.to} hashSpy={true} smooth={true} onClick={() => setShowMobileMenu(false)} className="menu-link">
                                                <div>{t(link.name)}</div>
                                            </Link>
                                            :
                                            <a href={link.href} className={'menu-link'}><div>{t(link.name)}</div></a>
                                        }

                                    </li>
                                ))}
                            </ul>

                        </nav>

                    </div>
                </div>
            </div>
            <div className="header-wrap-clone"/>
        </header>
    );
}
export default Header;