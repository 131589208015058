import React, {useEffect, useState} from "react";
import * as Scroll from "react-scroll";

const scroll    = Scroll.animateScroll;
const GoToTop = () => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handleScroll = () => {
        let scrollOffset = document.getElementById('root').getBoundingClientRect().top;
        if(Math.abs(scrollOffset) > 500) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    return <div id="gotoTop" onClick={() => scroll.scrollToTop()} className={"icon-angle-up " + (visible ? ' gototop-active' : '')}/>
}

export default GoToTop;